export default {
  getDiscussionComments(state) {
    return state.topicComments;
  },
  getPrivateDiscussionComments(state) {
    return Array.from(state.privateTopicComments.values());
  },
  getPublicDiscussionComments(state) {
    return Array.from(state.publicTopicComments.values());
  },
  getchatsSkeletonLoader(state) {
    return state.chatsSkeletonLoader;
  },
  getNextUrl(state) {
    return state.nextUrl;
  },
  getTopicChats(state) {
    return state.topicChats;
  },
  privateTopicDiscussionUsers: state => state.privateDiscussionUsers,
  publicTopicDiscussionUsers: state => state.publicDiscussionUsers,
  discoverTopicDiscussionLoader: state => state.discoverTopicDiscussionLoader,
  selectedDiscussionArticleId: state => state.selectedDiscussionArticleId,
  getDiscussionInvitedUsers: state => state.topicDiscussionInvitedUsers,
  privateDiscussionChatCurrentPage : state => state.privateDiscussionChatCurrentPage,
  publicDiscussionChatCurrentPage : state => state.publicDiscussionChatCurrentPage,
  isMoreDiscussionChatPage: state => state.isMoreDiscussionChatPage,
  typingUsers: state => state.typingUsers
};

export const __generateFolderFetchContentRequestUrl = ({folderId, contentType, searchQuery, page, pageSize}) => {
    let requestUrl = `discover/folder-tab/folder/${folderId}/search-content/?content=${contentType}`;
    if (searchQuery) {
        requestUrl += `&search=${searchQuery}`;
      }
    if (page) {
        requestUrl += `&page=${page}`;
    }
    if (pageSize) {
        requestUrl += `&page_size=${pageSize}`;
    }

    return requestUrl
}


export default {
  SET_NOTIFICATION_SOCKET(state, socketConnection){
    state.notificationSocket = socketConnection
  }, 
  INSERT_NEW_NOTIFICATION(state, notification){
    state.notifications = [notification, ...state.notifications]
  },
  SET_NOTIFICATIONS(state, notification){
    state.notifications = notification
  },
  SET_NEW_NOTIFICATION_COUNT(state, count){
    state.newNotificationCount = count
  },
  INCREMENT_NEW_NOTIFICATION_COUNT(state){
    state.newNotificationCount++
  },
};
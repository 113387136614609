<template>
  <div class="news-environment-section" id="for-active-navbar">
    <!-- <h2 class="news-environment-section__sub-title">
      NEWS ENVIRONMENT POWER BY <br/> <span>HUMANS + AI</span>
    </h2> -->
    <h1 class="news-environment-section__title">
      An Unparalleled <br /> News Environment Powered by <br /> <span>HUMANS <span>+</span> AI</span>
    </h1>
    <div class="news-environment-section__body">
      <a-row>
        <a-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9" class="content-col">
          <div class="content">
            <h1>Experience News <br /> Superintelligence!</h1>
            <p>Collaborate on news with AI.</p>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15" class="image-col">
          <div class="image">
            <img src="https://imagespictures.nyc3.digitaloceanspaces.com/Assets/branding/page/image-1.png" />
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15" class="image-col">
          <div class="image">
            <img src="https://imagespictures.nyc3.digitaloceanspaces.com/Assets/branding/page/image-2.png" style="height: 80rem;" />
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9" class="content-col">
          <div class="content">
            <h1>News Accountability</h1>
            <p>Rate articles on accuracy & credibility.</p>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9" class="content-col">
          <div class="content">
            <h1>Tailored News</h1>
            <p>Select the sources & keywords you need.</p>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15" class="image-col">
          <div class="image">
            <img  loading="lazy" src="https://imagespictures.nyc3.digitaloceanspaces.com/Assets/branding/page/image-3.png" />
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15" class="image-col">
          <div class="image">
            <img  loading="lazy" src="https://imagespictures.nyc3.digitaloceanspaces.com/Assets/branding/page/image-4.png" />
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9" class="content-col">
          <div class="content">
            <h1>Collaboration <br /> Workspace</h1>
            <p>Collaborate on news with your contacts.</p>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9" class="content-col">
          <div class="content">
            <h2>Coming Soon!</h2>
            <h1>Topic Heatmap <br/> Powered by AI</h1>
            <p>Your support makes this possible.</p>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15" class="image-col">
          <div class="image">
            <img  loading="lazy" src="https://imagespictures.nyc3.digitaloceanspaces.com/Assets/branding/page/image-5.png" />
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<style lang="scss">
.news-environment-section {
  padding: 13rem 10rem 8rem;
  @include respond(laptop-large) {
    padding: 13rem 5rem 8rem;
  }
  @include respond(tab-port) {
    padding: 10rem 4rem 10rem;
  }
  &__sub-title {
    font-family: $font-primary-medium;
    color: $color-primary;
    font-weight: 700;
    line-height: 3.1rem;
    font-size: 2.4rem;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    @include respond(phone-x-small) {
      font-size: 3rem;
      line-height: 4rem;
    }
    span {
      color: yellow;
    }
  }
  &__title {
    font-family: $font-primary-bold;
    color: $color-white;
    font-weight: 700;
    line-height: 7.5rem;
    font-size: 6rem;
    text-transform: uppercase;
    max-width: 85%;
    text-align: center;
    margin: 0 auto 14rem;
    position: relative;
    z-index: 1;
    // max-width: 102rem;
    @include respond(laptop-small) {
      font-size: 4.4rem;
      line-height: 5rem;
      max-width: 100%;
    }
    @include respond(tab-port) {
      font-size: 4rem;
      line-height: 5rem;
      max-width: 100%;
      margin-bottom: 8rem;
    }
    span {
      color: yellow;
      span {
        color: $color-white;
      }
    }
  }
  &__body {
    .ant-row {
      row-gap: 14rem !important;
      margin: 0;
      @include respond(tab-port) {
        row-gap: 9rem !important;
      }
      .ant-col {
        padding: 0 1rem;
      }
      // Mobile (default) mein natural flow
      @include respond(tab-port) {
        .content-col:nth-child(1) { order: 1; } // Content 1
        .image-col:nth-child(2) { order: 2; }  // Image 1
        .content-col:nth-child(4) { order: 3; } // Content 2
        .image-col:nth-child(3) { order: 4; }  // Image 2
        .content-col:nth-child(5) { order: 5; } // Content 3
        .image-col:nth-child(6) { order: 6; }  // Image 3
        .content-col:nth-child(8) { order: 7; } // Content 4
        .image-col:nth-child(7) { order: 8; }  // Image 4
        .content-col:nth-child(9) { order: 9; } // Content 4
        .image-col:nth-child(10) { order: 10; }  // Image 4
      }

      @include respond(tab-land) {
        .content-col { order: unset; }
        .image-col { order: unset; }
      }
    }
    .image {
      width: 100%;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 767px) {
        text-align: center;
        margin-top: 4rem;
      }
      h1 {
        font-family: $font-primary-medium;
        color: $color-white;
        font-weight: 700;
        line-height: 6rem;
        font-size: 5rem;
        text-transform: uppercase;
        margin-bottom: 1rem;
        @media (max-width: 1120px) {
          line-height: 5.2rem;
          font-size: 4rem;
        }
        @media (max-width: 992px) {
          line-height: 4.5rem;
          font-size: 3.5rem;
        }
        @media (max-width: 767px) {
          line-height: 5.5rem;
          font-size: 4.5rem;
        }
      }
      h2 {
        font-family: $font-primary;
        color: yellow;
        font-weight: 400;
        line-height: 4rem;
        font-size: 2.6rem;
        margin-bottom: 1rem;
        text-transform: uppercase;
      }
      p {
        font-family: $font-primary;
        color: $color-white;
        font-weight: 400;
        line-height: 4rem;
        font-size: 3.2rem;
        margin-bottom: 0;
        @media (max-width: 1120px) {
          line-height: 3rem;
          font-size: 2.5rem;
        }
        @media (max-width: 767px) {
          line-height: 4rem;
          font-size: 3rem;
        }
      }
    }
  }
}
</style>

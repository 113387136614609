const DB_NAME = 'glocal'
const DB_VERSION = '1'
let DB;
const stores = [
    { name: "linkedAccounts", key: "id" },
    { name: "users", key: "id" },
]

export default {
    async initialize() {
        return new Promise((resolve, reject) => { 
            if(DB) return resolve(DB)
            let request = window.indexedDB.open(DB_NAME,DB_VERSION)
            request.onerror = e => reject(e)
            request.onsuccess = e => { DB = e.target.result; resolve(DB) }
            request.onupgradeneeded = e => { 
                let db = e.target.result
                stores.forEach(store => { 
                    try {
                    if(db.objectStoreNames.contains(store.name))
                        db.deleteObjectStore(store.name)
                        db.createObjectStore(store.name, { keyPath: store.key })
                    }
                    catch (err) {
                        // Sentry.captureException(err);
                        console.log(err)
                    }
                })
            }
        })
    },

    async saveToDb(key, data) { 
         let db  = await this.initialize()
        return new Promise((resolve) => {
            try {
                let trans = db.transaction([key], 'readwrite')
                trans.oncomplete = () => resolve()
                let store = trans.objectStore(key);
                if (Array.isArray(data)) {
                    data.forEach(updateOrder => store.put(updateOrder))
                }
                else {
                    store.put(data);
                }
            }
            catch (err) { 
                console.log("INDEX DB", err)
                // Sentry.captureException(err);
                //pass
            }
        })
    },

    async getDbData(key) {
        let db = await this.initialize();
        return new Promise(resolve => {
            try {
                let trans = db.transaction([key], 'readonly');
                trans.oncomplete = () => {
                    resolve(dbData);
                };
                let store = trans.objectStore(key);
                let dbData = [];
                store.openCursor().onsuccess = e => {
                    let cursor = e.target.result;
                    if (cursor) {
                        dbData.push(cursor.value)
                        cursor.continue();
                    }
                };
            }
            catch (err) { 
                // Sentry.captureException(err);
                console.log(err,key, "INDEX DB")
            }
		});
    },

    async clearAllData(store = null) { 
        /**
         * @param {Array ,String} [store] - array of index db store names of name of index db store
         * 
         * @example
         *  clearAllData()
         *  clearAllData(["orders","categories"])
         *  clearAllData("orders")
         */
        const db = await this.initialize()
        return new Promise((resolve, reject) => { 
            try {
                if (!store) {
                    stores.forEach(store => { 
                    const trans = db.transaction(store.name, 'readwrite')
                    const requestedStore = trans.objectStore(store.name)
                    requestedStore.clear()
                    trans.oncomplete = () => { resolve() }
                    })
                }
                else if (Array.isArray(store)) {
                    store.forEach(store => { 
                    const trans = db.transaction(store, 'readwrite')
                    const requestedStore = trans.objectStore(store)
                    requestedStore.clear()
                    trans.oncomplete = () => { resolve() }
                    })
                }
                else if (typeof store === "string") {
                    const trans = db.transaction([store], 'readwrite')
                    const requestedStore = trans.objectStore(store)
                    requestedStore.clear()
                    trans.oncomplete = () => { resolve() }
                }
            }
            catch (err) { 
                console.log("INDEX db", err)
                // Sentry.captureException(err);
                reject()
            }
            
        })
    },

    async deleteFromDb(storeName, key) { 
        let db = await this.initialize();
        return new Promise((resolve, reject) => {
            try {
                let trans = db.transaction([storeName], 'readwrite');
                trans.oncomplete = () => resolve();
                let store = trans.objectStore(storeName);
                store.delete(key); // identifier is the key of the item you want to delete
            }
            catch (err) { 
                // Sentry.captureException(err);
                reject(err); // Reject the promise if there's an error
            }
        });
    }

}
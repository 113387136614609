import Cookies from "js-cookie";
import ApiService from "../../../services/api.service.js";

export default{
   initiateNotificationSocketConnection({commit, dispatch}){
      const accessToken = Cookies.get('access_token')
      const socketUrl = `${process.env.VUE_APP_ROOT_WEBSOCKET}notification/?token=${accessToken}`
      const socketConnection = new WebSocket(socketUrl)
      socketConnection.onopen = (connection)=>{
         if (connection){
            commit('SET_NOTIFICATION_SOCKET', socketConnection)
            socketConnection.onmessage = (message)=>{
               const notification = JSON.parse(message.data).data
               commit("INSERT_NEW_NOTIFICATION", notification)
               commit('INCREMENT_NEW_NOTIFICATION_COUNT')
               dispatch('toast/showToast', {message: `${notification?.full_name} ${notification?.verb.toLowerCase()}`, type:'notification'}, {root:true})

            } 
         }
      }
   },
   async fetchNotifications({commit}){
      return await new Promise((resolve, reject) => {
         ApiService.setAuthorizationHeader();
         ApiService.get("notifications/?public=true&page_size=10")
            .then((response) => {
               commit("SET_NOTIFICATIONS", response.data.results);
               resolve(response);
            })
            .catch((err) => {
               reject(err);
            });
      });
   },
   async fetchNotificationCount(context, _1) {
      return await new Promise((resolve, reject) => {
         ApiService.setAuthorizationHeader();
         ApiService.get("notifications/unread-count/")
            .then((response) => {
               if (response.status === 200) {
               context.commit("SET_NEW_NOTIFICATION_COUNT", response.data?.total_unread);
               resolve(response);
               }
            })
            .catch((err) => {
               reject(err);
            });
      });
   },
   async markAllNotificationsAsRead(_) {
      ///setting authorization headers
      ApiService.setAuthorizationHeader();
      // calling api
      return new Promise((resolve, reject) => {
        ApiService.put(`notifications/mark-as-read/`)
          .then((response) => {
            if (response.status === 200) {
              resolve(response);
            }
          })
          .catch((err) => reject(err));
      });
   },
   async updateNotificationsAlert({state}, payload) {
      ApiService.setAuthorizationHeader();
      return await new Promise((resolve, reject) => {
        ApiService.patch(`notifications/${payload.id}/update/`, payload)
          .then((response) => {
            if (response.status === 200) {
               state.notifications = state.notifications.map(notification=>{
                   return notification?.id === payload?.id ? 
                   {
                     ...response.data
                   } 
                   : 
                   {
                     ...notification
                   }
                  
               })
              resolve(response);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
   },
   async deleteNotification({commit, state}, payload) {
      ApiService.setAuthorizationHeader();
      return await new Promise((resolve, reject) => {
        ApiService.patch(`notifications/${payload.id}/update/`,{ deleted: "true"})
          .then((response) => {
            state.notifications = state.notifications.filter(notification=> notification?.id !== payload?.id)
            // if (response.status === 200) {
              resolve(response);
            // }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
}
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      archiveFolderList: [],
      folderSkeleton: false,
      selectedArchiveFolder: {},
      folderContentList: [],
      fetchContentLoader: false,
      selectedArchiveContentType: 'articles',
      archiveContentTotalCount: 0,
      currentArchiveContentPage: 1,
      archiveArticlePageSize: 3,
      archiveVideoAndPodcastPageSize: 9,
      hasMoreArchiveContent: false,
      archiveSuggestedFolderContentList: []
    };
  },
  actions,
  mutations,
  getters,
};

<template>
  <a-modal :visible="visible" @cancel="closeModal" :footer="null" class="request-demo-modal">
    <div class="request-demo-modal-container">
      <div class="request-demo-modal-container__header">
        <img src="@/assets/images/Amblem2@2x.webp" alt="Glocal Logo" class="request-demo-modal-container__header--logo" />
        <h1 class="request-demo-modal-container__header--title">
          Institutions Can Request a Live Product Demo
        </h1>
      </div>
      <form @submit.prevent="subscribeToGlocal" class="request-demo-modal-container__body">
        <input v-model="email" type="email" placeholder="Email Address" />
        <p v-if="error.email" class="error">{{ errorMsg }}</p>
        <a-row :gutter="[18, 2]">
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <input v-model="firstName" type="text" placeholder="First Name" />
            <p v-if="error.firstName" class="error">This field is required</p>
          </a-col>
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <input v-model="lastName" type="text" placeholder="Last Name" />
            <p v-if="error.lastName" class="error">This field is required</p>
          </a-col>
        </a-row>
        <input v-model="companyName" type="text" placeholder="Institution" />
        <p v-if="error.companyName" class="error">This field is required</p>
        <input v-model="jobTitle" type="text" placeholder="Job Title" />
        <p v-if="error.jobTitle" class="error">This field is required</p>
        <p v-if="error.errorMsg" class="error">{{ error.errorMsg }}</p>
        <a-button htmlType="submit" :loading="loader" class="submit-btn">
          Submit
        </a-button>
      </form>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },
  data() {
    return {
      email: "",
      firstName: "",
      lastName: "",
      companyName: "",
      jobTitle: "",
      loader: false,
      dropdown: false,
      errorMsg: "This field is required",
      error: {
        email: false,
        firstName: false,
        lastName: false,
        companyName: false,
        jobTitle: false,
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeDemoModal", false);
      this.emptyFields();
      this.error = {}
    },
    openDropdown() {
      this.dropdown =  !this.dropdown
    },
    validateForm(){
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email || !emailRegex.test(this.email)) {
        this.error.email = true;
        this.errorMsgEmail = !this.email ? "Email is required" : "Invalid email";
      }
      this.error.firstName = !this.firstName;
      this.error.lastName = !this.lastName;
      this.error.companyName = !this.companyName;
      this.error.jobTitle = !this.jobTitle;
      for (const key in this.error) {
        if (this.error[key]) {
          return false;
        }
      }
      return true;
    },
    async subscribeToGlocal() {
      this.error = {}
      if (!this.validateForm()) {
        return false;
      }else{
        try {
          this.loader = true;
          const payload = {
            email: this.email,
            first_name: this.firstName,
            last_name: this.lastName,
            company: this.companyName,
            job_title: this.jobTitle,
          }
          const response = await this.$store.dispatch("auth/requestDemo", payload);
          if (response.status === 201) {
            this.closeModal();
            this.emitter.emit("showSuccessModal")
          }
          this.loader = false;
        } catch (err) {
          this.loader = false;
          console.log(err);
        }
      }
    },
    emptyFields(){
      this.email = "",
      this.firstName = "",
      this.lastName = "",
      this.companyName = "",
      this.jobTitle = ""
    }
  },
};
</script>

<style lang="scss">
.request-demo-modal {
  width: 50.3rem !important;
  top: 10rem;

  .ant-modal-content {
    .ant-modal-close {
      margin: 3.2rem 3rem 0 0;
    }

    .ant-modal-body {
      .request-demo-modal-container {
        padding: 3.7rem;

        &__header {
          display: flex;
          align-items: center;
          flex-direction: column;

          &--logo {
            width: 3.6rem;
            height: auto;
            margin-bottom: 2.4rem;
          }

          &--title {
            font-size: 2rem;
            font-family: $font-primary-bold;
            margin-bottom: 0rem;
            line-height: 2.7rem;
            margin-left: 1.6rem;
            color: $color-black;
          }
        }

        &__body {
          margin-top: 2.7rem;

          input {
            height: 4.6rem;
            padding: 0 1.4rem;
            border: 1px solid #00000033;
            width: 100%;
            outline: none;
            border-radius: 7px !important;
            margin: 1.5rem 0 0.5rem;
            font-size: 1.4rem;
            font-family: $font-primary;
            line-height: normal;
            color: $color-black;

            &::placeholder {
              color: #7b7b7b;
              opacity: 1;
            }
          }

          .error {
            color: red;
            font-size: 1.4rem;
            margin-bottom: 5px;
          }
          .submit-btn {
            background-color: $color-primary;
            color: $color-white;
            font-size: 1.6rem;
            line-height: normal;
            font-family: $font-primary-medium;
            outline: none;
            border: none;
            border-radius: 7px !important;
            height: 4.6rem;
            width: 100%;
            cursor: pointer;
            margin: 2.5rem 0 1.4rem;
          }
        }
      }
    }
  }
}
</style>

import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
          // folderTabsList: [],
          // folderTabsSkeleton: true,
          foldersTree: [],
          folderTreeSkeleton: true,
          tabName: "",
          parentFolderDetails: {},
          childFolderDetails: {},
          nestedChildFolderDetails: {},
          folderArticleContent: [],
          folderMediaContent: [],
          folderPodcastContent: [],
          folderBookContent: [],
          folderArticleSkeleton: false,
          folderMediaSkeleton: false,
          folderPodcastSkeleton: false,
          archivedFolder: [],
          instituteUsers: [],
          usersWithFolderAccessCounts: {},
          usersWithFolderAccess: [],
          folderComments: [],
          folderActivity: [],
          folderActivitySkeleton: false,
          folderRequested: false,
          requestedFolderId: null,
          archive: false,
          page: 0,
          folderTabs:[
            {
              label:'Public',
              value:'public_folders'
            },
            {
              label:'Private',
              value:'private_folders'
            },
            {
              label:'Organization',
              value:'organization_folders'
            },
          ],
          folderSuggestedArticleContent:[],
          showArchive: false,
          activeFolderTab: 'private_folders',
          folderSuggestedArticleSkeleton: false,
          folderUninvitedUsers:[],
          activeFolder: {},
        };
    },
    actions,
    mutations,
    getters
}
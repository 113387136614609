const excludedQueryModules = ['topic-news', 'topic-research']

const generateSearchQuery = (params, searchModule) => {
    let query = params.getSearchedTopic && params.getSearchQuery ? `${params.getSearchedTopic} AND ${params.getSearchQuery}` : params.getSearchedTopic || params.getSearchQuery;

    if (params.includedKeywords) {
        query += params.getSearchedTopic || params.getSearchQuery ? "," + params.includedKeywords : params.includedKeywords 
    }
    if (params.excludedKeywords){
        query += params.includedKeywords ? " AND " + params.excludedKeywords : params.excludedKeywords 
    }
    if (params.exactKeyword)
        query += query ? " AND " + params.exactKeyword : params.exactKeyword 

    return excludedQueryModules.includes(searchModule) ? '' : query

}

const generateSearchPayload = (query, params, searchModule, limit=18) => {

    // trending without filter => isPDB = 1
    // trending with filter => isPDB = 0
    // topic regardless of filters => isPDB = 0
    const hasParameters = (params.getSearchedTopic || params.getSearchQuery || params.startDate || params.endDate || params.getIncludedSources || params.getExcludedSources || params.includedKeywords || params.excludedKeywords || params.exactKeyword) ? true : false
   
    const isPDB = excludedQueryModules.includes(searchModule) ? 0 : hasParameters ? 0 : 1

    return {
        searchedQuery: params.getSearchQuery,
        fromDate: params.startDate ?? null,
        toDate: params.endDate ?? null,
        offset: params.pageOffset,
        limit,
        includeSource: params.includedSources,
        excludeSource: params.excludedSources,
        includedKeywords: params.includedKeywords,
        excludedKeywords: params.excludedKeywords,
        exactKeyword: params.exactKeyword ?? '',
        searchedTopic: params.getSearchedTopic ?? '',
        sortBy: params.getSortFilter || "relevancy",
        isPDB

    }
}

export const __getSearchParams = (getters, searchModule) => {
    /**
     * trending search will be used by the search bar only.
     * news search module is limited to news section of the selected topic excluding Trending news topic
     * research search module is limited to new section of the selected topic excluding Trending news topic
     */

    // debugger // eslint-disable-line no-debugger
    
    let getSearchedTopic = ''
    let getSearchQuery = ''
    let startDate = ''
    let endDate = ''
    let includedSources = ''
    let excludedSources = ''
    let pageOffset = 0
    let includedKeywords = ''
    let excludedKeywords = ''
    let exactKeyword = ''
    
    switch (searchModule){
        case 'topic-news':
            getSearchedTopic = getters?.getSearchedTopic
            getSearchQuery = getters?.getSearchQuery
            startDate = getters?.getStartDate
            endDate = getters?.getEndDate
            includedSources = getters?.getIncludedSources
            excludedSources = getters?.getExcludedSources
            pageOffset = getters?.getArticlesPageOffset
            includedKeywords = getters?.getPDBIncludedKeywords.join(',') 
            excludedKeywords = getters?.getPDBExcludedKeywords.map(word=>`${word}`).join(',')
            exactKeyword = getters?.pdbExactKeyword ?? ''
            break;
        case 'topic-research':
            getSearchedTopic = getters?.getSearchedTopic
            getSearchQuery = getters?.getSearchQuery
            startDate = getters?.getInsightsStartDate
            endDate = getters?.getInsightsEndDate
            includedSources = getters?.getInsightsIncludedSources
            excludedSources = getters?.getInsightsExcludedSources
            pageOffset = getters?.getInsightsPageOffset
            includedKeywords = getters?.getInsightsIncludedKeywords.join(',')
            excludedKeywords = getters?.getInsightsExcludedKeywords.map(word=>`${word}`).join(',')
            exactKeyword = getters?.insightExactKeyword ?? ''
            break;
        case 'trending-news':
            getSearchQuery = getters?.trendingNewsFilters?.searchedKeyword /// keyword will be used of news filter only in both research and news
            startDate = getters?.trendingNewsFilters?.startDate ?? ''
            endDate = getters?.trendingNewsFilters?.endDate ?? ''
            includedSources = getters?.trendingNewsFilters?.includedSources.map(source=>source?.source_name).join(",")
            excludedSources = getters?.trendingNewsFilters?.excludedSources.map(source=>source?.source_name).join(",")
            pageOffset = getters?.getArticlesPageOffset // same variable will be used as topic-news
            includedKeywords = getters?.trendingNewsFilters?.includedKeywords.join(',') 
            excludedKeywords = getters?.trendingNewsFilters?.excludedKeywords.map(word=>`${word}`).join(',')
            exactKeyword =  getters?.trendingNewsFilters?.exactKeyword ?? ''
            break;
        case 'trending-research':
            getSearchQuery = getters?.trendingNewsFilters?.searchedKeyword /// keyword will be used of news filter only in both research and news
            startDate = getters?.trendingResearchFilter?.startDate ?? ''
            endDate = getters?.trendingResearchFilter?.endDate ?? ''
            includedSources = getters?.trendingResearchFilter?.includedSources.map(source=>source?.source_name).join(",")
            excludedSources = getters?.trendingResearchFilter?.excludedSources.map(source=>source?.source_name).join(",")
            pageOffset = getters?.getInsightsPageOffset // same variable will be used as topic-research
            includedKeywords = getters?.trendingResearchFilter?.includedKeywords.join(',') 
            excludedKeywords = getters?.trendingResearchFilter?.excludedKeywords.map(word=>`${word}`).join(',') 
            exactKeyword = getters?.trendingResearchFilter?.exactKeyword ?? ''
            break;
    }

    return {
        getSortFilter:  getters?.getSortFilter ?? '' ,
        getSearchedTopic,
        getSearchQuery,
        startDate,
        endDate,
        includedSources,
        excludedSources,
        pageOffset,
        includedKeywords,
        excludedKeywords,
        exactKeyword
    }
}

export const __searchFunction = (fn, getters, limit=18 ,searchModule) => {

    const params = __getSearchParams(getters, searchModule)
    const query = generateSearchQuery(params, searchModule)
    const payload = generateSearchPayload(query, params, searchModule, limit)

    return fn(payload)
}

export const __setTrendingFilters = (state, module='trending-news', filters) =>{

    Object.entries(filters).forEach(([key, value])=>{
        if (Object.hasOwn(module === 'trending-news' ? state.trendingNewsFilter :  state.trendingResearchFilter, key))
            module === 'trending-news' ? state.trendingNewsFilter[key] = value :  state.trendingResearchFilter[key] = value
    })
}

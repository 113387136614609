import ApiService from "../../../services/api.service";
import { __searchFunction, __setTrendingFilters, __getSearchParams} from "../../helpers/discover.helper"

export default {
  // Searching articles for login User

  searchArticles(context, _) {
    context.commit("SEARCHING_PDB_ARTICLES", true);

    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      __searchFunction((payload)=>{
          let requestUrl = `discover/search/?limit=${payload.limit}&offset=${payload.offset}&is_home_page=${payload.isPDB}`
          if (payload.includeSource){
             requestUrl+=`&included_sources=${payload.includeSource}`
          }
          if (payload.excludeSource){
             requestUrl+=`&excluded_sources=${payload.excludeSource}`
          }
          if(payload.searchedQuery){
            requestUrl+=`&query=${payload.searchedQuery}`
          }
          if (payload.fromDate || payload.toDate) {
            requestUrl += `&date_range=${payload.fromDate},${payload.toDate}`;
          }
          if (payload.sortBy) {
            requestUrl += `&sort_by=${payload.sortBy}`;
          }
          if(payload.includedKeywords)
          {
            requestUrl += `&included_keywords=${payload.includedKeywords}`; 
          }
          if(payload.excludedKeywords)
          {
            requestUrl += `&excluded_keywords=${payload.excludedKeywords}`; 
          }
          if(payload.exactKeyword || payload.searchedTopic)
          {
            requestUrl += `&exact_match=${payload.exactKeyword ? payload.exactKeyword : payload.searchedTopic}`; 
          }
          ApiService.get(requestUrl)
            .then((response) => {
              if (response.status === 200) {
                context.commit("SET_ARTICLES", response.data);
                context.commit("SEARCHING_PDB_ARTICLES", false);
                resolve(response);
              }
            })
            .catch((err) => {
              reject(err);
            });
      }, context.getters, 18, context.state.activeTopicTab === 'Trending news' ? 'trending-news' : 'topic-news')
    });
  },

  // Searching articles for non login User

  searchArticlesAnonymousUser(context, payload) {
    return new Promise((resolve, reject) => {
      let requestUrl = `discover/search/anonymous?query=${payload.query}&limit=${payload.limit}&offset=${payload.offset}&include_source=${payload.includeSource}&exclude_source=${payload.excludeSource}&is_research_institute=false`;
      if (payload.fromDate) {
        requestUrl += `&from_date=${payload.fromDate}`;
      }
      if (payload.toDate) {
        requestUrl += `&to_date=${payload.toDate}`;
      }
      ApiService.get(requestUrl)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_ARTICLES", response.data);
            context.commit("SET_LIMIT_ERROR", "");
            resolve(response);
          }
        })
        .catch((err) => {
          if (err.response.status === 429) {
            context.commit("SET_ARTICLES", []);
          }
          context.commit("SET_LIMIT_ERROR", err.response.data.detail);
          reject(err);
        });
    });
  },

  // Get all new sources for login User

  async getAllSources(context) {
    context.commit("SEARCHING_PDB_SOURCES", true);
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.get(`sources/?is_research_institute=false`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SEARCHING_PDB_SOURCES", false);
            context.commit("SET_ALL_SOURCES", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get all insights sources for login User

  async getAllInsightsSources(context, _) {
    context.commit("SEARCHING_INSIGHTS_SOURCES", true);
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.get(`sources/?is_research_institute=true`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SEARCHING_INSIGHTS_SOURCES", false);
            context.commit("SET_ALL_INSIGHTS_SOURCES", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get all sources for non login User

  async getAllSourcesAnonymous(context) {
    return await new Promise((resolve, reject) => {
      ApiService.get(`sources/anonymous/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_ALL_SOURCES", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get question rating for login User

  async getQuestionRating(context, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.get(`workspace/question_rating/${payload.articleId}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_QUESTION_RATING", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get question rating for non login User

  async getQuestionRatingAnonymous(context, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.get(
        `workspace/question_rating/${payload.articleId}/anonymous/`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_QUESTION_RATING", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getRating(context, payload) {
    return await new Promise((resolve, reject) => {
      let requestUrl = `workspace/rating/${payload.article_id}/`;
      requestUrl += payload.rating_question
        ? `?rating_question=${payload.rating_question}/`
        : "";
      requestUrl += payload.rate ? `?rate=${payload.rate}` : "";
      ApiService.setAuthorizationHeader();
      ApiService.get(requestUrl)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_QUESTION_RATING", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Create Justifications

  createRatings(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`workspace/rating/create/`, payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Submit Justifications

  updateRatings(_, payload) {
    let articleId;
    payload.forEach((element) => {
      articleId = element.article;
    });
    return new Promise((resolve, reject) => {
      ApiService.patch(`workspace/rating/${articleId}/update/`, payload)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Delete Justifications

  deleteRatings(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`workspace/rating/${payload.article_id}/destroy/`)
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get all ratings and justifications

  seeAllJustifications(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `workspace/rating/${payload.article_id}/question/${payload.question_id}/all-ratings/`
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Searching insights articles for login User

  searchInsights(context, _) {
    context.commit("SEARCHING_INSIGHTS_ARTICLES", true);

    return new Promise((resolve, reject) => {
      __searchFunction((payload)=>{

        let requestUrl = `discover/insights?limit=${payload.limit}&offset=${payload.offset}&included_sources=${payload.includeSource}&excluded_sources=${payload.excludeSource}&is_home_page=${payload.isPDB}`;
        if(payload.searchedQuery){
          requestUrl+=`&query=${payload.searchedQuery}`
        }
        if (payload.fromDate || payload.toDate) {
          requestUrl += `&date_range=${payload.fromDate},${payload.toDate}`;
        }
        if (payload.sortBy) {
          requestUrl += `&sort_by=${payload.sortBy}`;
        }
        if(payload.includedKeywords)
        {
          requestUrl += `&included_keywords=${payload.includedKeywords}`; 
        }
        if(payload.excludedKeywords)
        {
          requestUrl += `&excluded_keywords=${payload.excludedKeywords}`; 
        }
        if(payload.exactKeyword || payload.searchedTopic)
        {
          requestUrl += `&exact_match=${payload.exactKeyword ? payload.exactKeyword : payload.searchedTopic}`; 
        }
        ApiService.get(requestUrl)
          .then((response) => {
            if (response.status === 200) {
              context.commit("SET_INSIGHTS", response.data);
              context.commit("SEARCHING_INSIGHTS_ARTICLES", false);
              resolve(response);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }, context.getters, 18, context.state.activeTopicTab === 'Trending news' ? 'trending-research' : 'topic-research')

    });
  },

  // Searching insights articles for non login User

  searchInsightsAnonymousUser(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `discover/insights/anonymous?query=${payload.query}&from_date=${payload.fromDate}&limit=${payload.limit}&offset=${payload.offset}&to_date=${payload.toDate}&include_source=${payload.includeSource}&exclude_source=${payload.excludeSource}&is_research_institute=true`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_INSIGHTS", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Request new news source to admin

  requestNewsSource(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`request-sources/`, payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Search filter

  saveSearchFilter(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`discover/search/saved-search/add/`, payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchSavedSearchFilter(context, _) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`discover/search/saved-search/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_SAVE_SEARCH_FILTER", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteSavedSearchFilter(_, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`discover/search/saved-search/${payload}/delete`)
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchSearchHistory(context, _1) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`discover/search/history/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_ARTICLE_SEARCH_HISTORY", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteRecentSearchHistory(_, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`discover/search/history/${payload}/delete`)
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Comments Of Discover Article

  discoverArticleComment({commit}, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/article/${payload.article_id}/comment/create/`,
        payload
      )
        .then((response) => {
          if (response.status === 201) {
            commit('PUSH_NEW_COMMENT', response.data)
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchDiscoverArticleComments(context, payload) {
    return new Promise((resolve, reject) => {
      let apiUrl = `discover/article/${payload.article_id}/comment/?ordering=-created_at`
      if(payload.filterOptions)
          apiUrl += `&date_range_after=${payload.filterOptions.endDate}&date_range_before=${payload.filterOptions.startDate}`
      ApiService.get(apiUrl)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_DISCOVER_ARTICLES_COMMENTS", response.data.results);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateDiscoverArticleComment(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `discover/article/${payload.article_id}/comment/update/${payload.comment_id}/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteDiscoverArticleComment(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/article/${payload.article_id}/comment/delete/${payload.comment_id}/`
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Comments Of Discussion Section

  createDiscussionComment(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/create/`,
        payload
      )
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchDiscussionComments(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_DISCUSSION_COMMENTS", response.data.results);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setPrivateDiscussionComments(context, payload) {
    console.log("THis is hitting Private in discover");
    return new Promise((resolve, reject) => {
      let requestUrl = `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/`;
      if (payload.filterOptions == "Newest") {
        requestUrl += `?ordering=-created_at`;
      }
      if (payload.filterOptions == "Top discussions") {
        requestUrl += `?ordering=-discussion_count`;
      }
      ApiService.get(requestUrl)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_PRIVATE_DISCUSSION_COMMENTS", response.data);
            context.commit("SET_DISCUSSION_LOADER", false);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setPublicDiscussionComments(context, payload) {
    console.log("THis is hitting Public in discover");
    return new Promise((resolve, reject) => {
      let requestUrl = `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/`;
      if (payload.filterOptions == "Newest") {
        requestUrl += `?ordering=-created_at`;
      }
      if (payload.filterOptions == "Top discussions") {
        requestUrl += `?ordering=-discussion_count`;
      }
      ApiService.get(requestUrl)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_PUBLIC_DISCUSSION_COMMENTS", response.data);
            context.commit("SET_DISCUSSION_LOADER", false);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateDiscussionCommentst(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/${payload.discussion_id}/update/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteDiscussionComments(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/${payload.discussion_id}/destroy/`
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getDiscoverModules(context, _) {
    return await new Promise((resolve, reject) => {
      context.state.glocalModuleLoader = true
      ApiService.get(`glocal-modules/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_MODULES", response.data);
            context.state.glocalModuleLoader = false
            resolve(response);
          }
        })
        .catch((err) => {
          context.state.glocalModuleLoader = false
          reject(err);
        });
    });
  },
  async updateDiscoverModules(context, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.patch(`/glocal-modules/update-order/${payload.id}/`, payload)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_MODULES", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async interestSuggestions({ commit }, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`interests/suggestions/?interest=${payload.name}`)
        .then((response) => {
          if (response.status === 200) {
            commit("SET_SUGGESTIONS", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setSelectedTopic({ commit }, topic) {
    commit("SET_SELECTED_TOPIC", topic);
  },
  async likeComment({state, commit}, actionDetails){
    return new Promise((resolve, reject)=>{
      const url = `discover/article/${actionDetails.article_id}/comment/${actionDetails.comment_id}/action/create/`
      ApiService.post(url,actionDetails.action).then(res=>{
        state.discoverArticleComments = state.discoverArticleComments.map(comment=>{

          if (res.data?.parent && comment.id === res.data?.parent){
            comment.child = comment.child.map(childComment=>{
              return childComment.id === res.data?.id ?
              {
                ...res.data
              }
              :
              {
                ...childComment
              }
            })
            return {...comment}
          }else{

          return comment.id === res.data?.id ?
          {
            ...res.data
          } 
          : 
          {
            ...comment
          }
          }
            // return comment.child.map(childComment=>{
            //   return childComment.id === res.data?.  
            // })
        })
        resolve(res)

      }).catch(err=>{
        reject(err)
      })
    })
  },
  async deleteLikeDislike(_, actionDetails){
    return new Promise((resolve, reject)=>{
      const url = `discover/article/${actionDetails.article_id}/comment/${actionDetails.comment_id}/action/delete/`
      ApiService.delete(url).then(res=>{
        if(res.status=== 204 )
          resolve()
      }).catch(err=>{
        reject(err)
      })
    })
  },
  setSelectedModule({commit}, module){
      commit('SET_SELECTED_DISCOVER_MODULE', module)
  },

  setTrendingNewFilters({state}, filters){
    __setTrendingFilters(state, 'trending-news', filters)
  },

  setTrendingResearchFilters({state}, filters){
    __setTrendingFilters(state, 'trending-research', filters)
  },

  emptyTrendingNewsFilters({state}, module='trending-news'){
    const trendingFilters = {
      includedKeywords: [],
      excludedKeywords: [],
      exactKeyword: '',
      includedSources: [],
      excludedSources: [],
      pageOffSet: 0
    }
    
    module === 'trending-news' ?  __setTrendingFilters(state, 'trending-news', trendingFilters) :  __setTrendingFilters(state, 'trending-research', trendingFilters)


  },
  emptyTrendingNewsSearchedKeyword({state}){
    state.trendingNewsFilter.searchedKeyword = ''
  },
  setActiveTopic({commit}, topic){
    commit('SET_ACTIVE_TOPIC', topic)
  },
  fetchAppliedFilters({getters}, searchModule){
    const params =  __getSearchParams(getters, searchModule)
    return {
      included_sources:  params?.includedSources.split(",").filter(source=>source),
      excluded_sources:  params?.excludedSources.split(",").filter(source=>source),
      included_keywords: params?.includedKeywords.split(" AND ").filter(keyword=>keyword),
      excluded_keywords: params?.excludedKeywords.replaceAll("-", "").split(" AND ").filter(keyword=>keyword),
      date_range: {lower:params?.startDate ? params?.startDate : null, upper:params?.endDate ? params?.endDate :  null},
      exact_match: params?.exactKeyword.replaceAll(/"/g,"")

    }
  },

  setPDBExactKeyWord ({commit}, keyword){
    commit('SET_PDB_EXACT_KEYWORD', keyword)
  },

  setInsightsExactKeyWord ({commit}, keyword){
    commit('SET_INSIGHTS_EXACT_KEYWORD', keyword)
  },
  setTopicDiscussionTypeId({commit}, id){
    commit('SET_TOPIC_DISCUSSION_TYPE_ID', id)
  },
  fetchUninvitedTopicUsers({state}){
    const topicId = state.topic.interest?.id
    return new Promise((resolve, reject)=>{
      ApiService.setAuthorizationHeader()
      ApiService.get(`discover/group/${state.activeTopicDiscussionTypeId}/discussion/${topicId}/comment/exclude-joined-contacts/users/`)
      .then(res=>{
        console.log(res.data)
        resolve(res.data.results)
      })
      .catch(err=>{reject(err)})
    })
  },

  fetchPDBInitialKeywords({state}){
    return new Promise((resolve, reject)=>{
      ApiService.setAuthorizationHeader()
      ApiService.get(`interests/starter/`)
      .then(res=>{
        resolve(res.data)
      })
      .catch(err=>{reject(err)})
    })
  },

  createPDBKeywords({commit, rootGetters}, payload){
    return new Promise((resolve, reject)=>{
      ApiService.setAuthorizationHeader()
      ApiService.put(`interests/your-pdb/`, payload)
      .then(res=>{
        let topics = rootGetters['profile/topicsCategories']
        topics.forEach(topic=>{
          if (topic.interest?.id === res.data.id)
            topic.interest = {...res.data}
        })
        commit('profile/setUsersCategories', topics, {root:true})
        resolve(res)
      })
      .catch(err=>{reject(err)})
    })
  },

  toggleInitialTopicModal({commit, state}){
    if (!state.openInitialTopicModal){
      localStorage.setItem('initialStep', true)
    }
    commit('SET_OPEN_INITIAL_TOPIC_MODAL', !state.openInitialTopicModal)
  },
  async getSourcesByCountry({commit}, is_research=false){
    commit("SEARCHING_PDB_SOURCES", true);
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.get(`sources/parted/?is_research_institute=${is_research ? 1 : 0}`)
        .then((response) => {
            commit("SEARCHING_PDB_SOURCES", false);
            // commit("SET_ALL_SOURCES_BY_COUNTRY", response.data.map(source=>({name:source.country, sources:source.sources?.map(source=>({...source, isChecked:false}))})));
            const commitVariable = is_research ? 'SET_ALL_INSIGHTS_SOURCES_BY_COUNTRY' : 'SET_ALL_SOURCES_BY_COUNTRY'
            commit(commitVariable, response.data.flatMap(source=>source.sources));
            resolve(response);
          
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setInitialJobId({commit}, jobId){
    commit('SET_INITIAL_JOB_ID', jobId)
  }


};
<template>
  <div :class="{ 'sticky': scrollClass }" class="header" ref="header">
    <div class="logo_box">
      <img class="glocal__logo" src="../../assets/images/logo@2x.webp" alt="logo" width="auto" height="auto" />
      <h1 class="glocal__text">Glocal</h1>
      <div>
        <div class="drawer" @mouseover="showDrawer" v-click-outside="closeDrawer">
          <!-- <img src="../../assets/icons/Drawer_Icon.svg" class="drawer-icon" alt="drawer icon" /> -->
          <transition name="drawer">
            <div class="drawer-dropdown" v-if="drawerVisible">
              <div class="drawer-dropdown__header">
                <img class="drawer-dropdown__img" src="../../assets/images/Amblem2@2x.webp" alt="glocal logo" />
                <p class="drawer-dropdown__heading">Glocal Insights</p>
              </div>
              <div class="drawer-dropdown__content">
                <div>
                  <p class="drawer-dropdown__heading">
                    Interested in the cause?
                  </p>
                  <p class="drawer-dropdown__para">Know more about us.</p>
                </div>
                <div class="drawer-dropdown__links">
                  <a href="">Our Team</a>
                  <span>&emsp;&#124; &emsp;</span>
                  <a href="">What's coming</a>
                  <span>&emsp;&#124; &emsp;</span>
                  <a href="">Know more &emsp;</a>&emsp;
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <!-- <div class="navbar" ref="navbar">
      <navbar></navbar>
    </div> -->
    <div class="register">
      <!-- <a-button class="register-btn login-btn" @click="showAccessModal('signup')">Join Now</a-button>register-btn login-btn -->
      <!-- <a-button data-cy="headerLoginButton" class="register-btn register-btn-hover color" type="primary" @click="showDemoModal()">Request a Demo</a-button> -->
      <!-- <a-button class="register-btn register-btn-hover color" type="primary" @click="waitListModalVisible=true">Get
        early Access</a-button> -->
    </div>
  </div>

  <access-modal
    :visible="visibleAccessModal"
    :type="modalType"
    @closeAccessModal="closeAccessModal"
    @showForgotPasswordModal="showForgotModalPass"
    @showOTPVerificationModal="()=>{this.OtpVerificationModalVisible = true}"
    >
  </access-modal>

  <forgot-password-modal
    :visible="forgotModalVisible"
    @cancel="forgotModalVisible=false"
    :footer="null">
  </forgot-password-modal>

  <OtpverificationModal
    :visible="OtpVerificationModalVisible"
    @close="OtpVerificationModalVisible=false"
  />

  <wait-list-modal
    :visible="waitListModalVisible"
    :footer="null"
    @close-modal="waitListModalVisible=false">
  </wait-list-modal>

  <account-activate
    :visible="accountActiveMoal"
    @show-message-modal="showActivationMessage"
    @cancel="handleActiveAcc"
    :footer="null">
  </account-activate>

  <request-demo-modal
    :visible="visibleDemoModal"
    @closeDemoModal="visibleDemoModal = false"
  >
  </request-demo-modal>

  <message-modal
  :visible="showSuccessMsg"
  :message="message"
  @close-modal="showSuccessMsg = false"
  >
  </message-modal>
</template>
<script>
// import Navbar from "./LandingPageNavbar.vue";
import ForgotPasswordModal from "../Auth/ForgotPasswordModal.vue";
import WaitListModal from "./WaitListModal.vue";
import AccountActivate from "../ArticleReadComponents/AccountActivate.vue";
import AccessModal from "../Auth/AccessModal.vue";
import RequestDemoModal from "../Auth/signUpGlocal/RequestaDemoModal.vue";
import MessageModal from "../BaseComponents/MessageModal.vue";
import {mapGetters} from "vuex";
import OtpverificationModal from "../Auth/OtpVerificationModal.vue"

export default {
  components: {
    ForgotPasswordModal,
    WaitListModal,
    AccountActivate,
    AccessModal,
    RequestDemoModal,
    MessageModal,
    OtpverificationModal
  },
  data() {
    return {
      drawerVisible: false,
      forgotModalVisible: false,
      waitListModalVisible: false,
      accountActiveMoal: false,
      message: {},
      showMessageModal: false,
      scrollClass: false,
      visibleAccessModal: false,
      modalType: "",
      visibleDemoModal: false,
      showSuccessMsg: false,
      OtpVerificationModalVisible: false,
    };
  },
  mounted(){
    if(this.$route.path === "/sign-in" || this.$route.query.topic || this.$route.query.article){
      this.visibleAccessModal = true,
      this.modalType = "login";
    }
    if (this.isFolderRequested) { 
      this.showAccessModal('login')
    }

    this.emitter.on('showSuccessModal', (val)=> {
      this.showSuccessMsg = true;
      this.message = {
        title: "Submitted",
        desc: "We received your demo request and look forward to speaking with you shortly.",
        type: "success",
      };
    })

    window.addEventListener('scroll', (val)=> {
      const section = document.getElementById(`for-active-navbar`);
      if(section.offsetTop > scrollY){
        this.scrollClass = false;
      }
      else if(section.offsetTop < scrollY){
        this.scrollClass = true;
      }

    });

    this.emitter.on('changeFormType', (val) => {
      this.modalType = val;
    })
  },
  computed:{ 
    ...mapGetters({
      isFolderRequested:'folders/isFolderRequested',
      linkedInLoginRedirection: 'auth/linkedInLoginRedirection'
    })
  },
  watch:{
    linkedInLoginRedirection:{
      handler(val){
        if(val)
          this.showAccessModal('signup')
      },
      immediate: true
    }
  },
  methods: {
    showAccessModal(value) {
      this.modalType = value;
      this.visibleAccessModal = true;
    },
    showDemoModal(){
      this.visibleDemoModal = true;
    },
    closeAccessModal(){
      this.visibleAccessModal = false;
    },
    handleActiveAcc() {
      this.accountActiveMoal = false;
    },
    showForgotModalPass() {
      this.forgotModalVisible = true
      this.closeAccessModal();
    },
    showActivateModal(showAccActModal) {
      this.accountActiveMoal = showAccActModal;
      this.closeAccessModal();
    },
    // showNav() {
    //   let navbar = this.$refs["navbar"];
    //   if (navbar.style.display === "none") {
    //     navbar.style.display = "block";
    //     this.$refs["register"].style.display = "flex";
    //     this.$refs["header"].style.paddingTop = "2.6rem";
    //   } else {
    //     navbar.style.display = "none";
    //     this.$refs["register"].style.display = "none";
    //     this.$refs["header"].style.paddingTop = "0";
    //   }
    // },
    showDrawer() {
      this.drawerVisible = true;
    },
    closeDrawer() {
      this.drawerVisible = false;
    },
    showActivationMessage() {
      this.message = {
        title: "Account activated",
        desc: "Please login to continue.",
        type: "success",
      };
      this.showMessageModal = true
      this.accountActiveMoal = false
    },
    closeOTPverificationModal(){
      this.OtpVerificationModalVisible = false
      this.visibleAccessModal = false

    }
  },
};
</script>
<style lang="scss" scoped>
@keyframes in {
  0% {
    top: 0;
    opacity: 0;
  }

  20% {
    top: 20%;
    opacity: 0.2;
  }

  40% {
    top: 40%;
    opacity: 0.4;
  }

  60% {
    top: 60%;
    opacity: 0.6;
  }

  80% {
    top: 80%;
    opacity: 0.8;
  }

  100% {
    top: 100%;
    opacity: 1;
  }
}

.drawer-enter-active {
  animation: in 0.2s linear;
}

.drawer-leave-active {
  animation: in 0.2s linear reverse;
}

.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: white;
  height: 14.2rem;
  background: transparent;
  opacity: 1;
  fill-opacity: 23%;
  z-index: 2;
  padding: 0 7.1rem;
  top: 0;
  width: 100%;

  @include respond(tab-port) {
    padding: 0 4rem;
  }


  .logo_box {
    display: flex;
    align-items: center;

    .drawer {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-left: 3.24rem;
      padding: 0;

      .drawer-dropdown {
        position: absolute;
        top: 3.9rem;
        width: 54.5rem;
        background-color: $color-white;
        border-bottom: 4px solid $color-primary;
        z-index: 10;
        padding-bottom: 1rem;

        @include respond(tab-land) {
          width: 56.5rem;
        }

        @include respond(tab-port) {
          width: 62.5rem;
        }

        &__header {
          display: flex;
          padding: 1.2rem 0 0.7rem 1.6rem;
          border-bottom: 1.7px solid #f0f0f0;
          align-items: center;
        }

        &__img {
          width: 1.8rem;
          height: 2.1rem;

          @include respond(tab-port) {
            margin-right: 1rem;
          }
        }

        &__heading {
          margin: 0;
          color: $color-black;
          padding-left: 0.5rem;
          font-family: $font-primary-bold;

          @include respond(tab-port) {
            font-size: 2.3rem;
          }
        }

        &__content {
          padding: 1.2rem 0 0 1.6rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &__para {
          margin: 0;
          color: $color-dark-blue-2;
          padding-left: 0.5rem;
          opacity: 0.8;
          font-size: 1.2rem;

          @include respond(tab-port) {
            font-size: 1.6rem;
          }
        }

        &__links {

          a,
          span {
            font-size: 1.2rem;
            color: $color-dark-blue-2;
            opacity: 0.6;
          }
        }
      }

      .drawer-dropdown-hover {
        display: block !important;
      }
    }
  }

  // .navbar {
  //   @include respond(tab-port) {
  //     display: none;
  //     order: 2;
  //     flex-basis: 100%;
  //     z-index: 5;
  //   }
  // }

  .menu-icon {
    height: 3rem;
    width: 3rem;
    fill: white;
    margin-left: 2rem;
  }

  .glocal {
    &__logo {
      width: 4.6rem;
      height: auto;
      @include respond(laptop-large) {
        width: 3.8rem;
      }
      @include respond(phone-x-small) {
        width: 4.5rem;
      }
      @media (max-width: 372px) {
        width: 4rem;
      }
    }

    &__text {
      color: $color-white;
      margin: 0 0 0 1rem;
      font-size: 3.4rem;
      font-family: $font-primary-bold;
      line-height: normal;
      @include respond(laptop-large) {
        font-size: 2.8rem;
        line-height: 3rem;
      }
      @include respond(phone-x-small) {
        font-size: 3.5rem;
      }
      @media (max-width: 372px) {
        font-size: 3rem;
      }
    }
  }

  .register {
    // @include respond(tab-port) {
    //   display: none;
    //   order: 3;
    //   flex-direction: column;
    //   align-items: center;
    //   background-color: #24407f;
    //   width: 100vw;
    //   margin: 0 -3rem;
    //   padding-bottom: 2rem;
    //   z-index: 6;
    // }

    .login-btn {
      color: $color-white;
      font-family: $font-primary-bold;
      font-size: 1.7rem;
      border-radius: 7px !important;
    }

    .register-btn {
      background-color: #688bff;
      border: none;
      font-family: $font-primary-medium;
      font-size: 1.44rem;
      height: auto;
      padding: 1rem 3.2rem;
      border-radius: 7px !important;

      @include respond(laptop-small) {
        font-size: 1.8rem;
      }
      @include respond(phone-x-small) {
        font-size: 2rem;
        padding: 1rem 2.5rem;
      }
      @media (max-width: 372px) {
        font-size: 1.5rem;
        padding: 1rem 2rem;
      }
    }
    .login-btn {
      background-color: transparent;
      border: 1px solid $color-white;
      padding: 0.9rem 3.2rem;
      @include respond(phone-x-small) {
        padding: 0.9rem 2rem;
      }
    }

    .register-btn-hover {
      background-size: 300% 100%;
      moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    .register-btn-hover:hover {
      background-position: 100% 0;
      moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    .register-btn-hover.color {
      background-image: linear-gradient(to right,
          #3660ec,
          $color-primary,
          $color-primary,
          #688bff,
        );
    }

  }

  // .hamburger {
  //   display: none;
  //   align-items: center;
  //   cursor: pointer;
  //   .icon{
  //     color: $color-white;
  //     filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
  //   }
  //   @include respond(tab-port) {
  //     display: block;
  //   }
  // }
}
.sticky {
  background-color: $color-black;
  margin: 0;
  height: 9.2rem;
}

</style>
import { __updateTopicDiscussionComments, __deleteTopicComment } from '@/store/helpers/discussion.helper.js'

export default {
  SET_DISCUSSION_COMMENTS(state, payload) {
    state.topicComments = payload;
  },
  SET_PRIVATE_DISCUSSION_COMMENTS(state, payload) {
    state.privateTopicComments = new Map(payload.map(comment => [comment.id, comment]));
  },
  SET_PUBLIC_DISCUSSION_COMMENTS(state, payload) {
    state.publicTopicComments =  new Map(payload.map(comment => [comment.id, comment]));
  },
  SET_TOPICS_CHATS(state, payload) {
    state.topicChats = payload;
  },
  SET_NEXT_URL(state, payload) {
    state.nextUrl = payload;
  },
  SET_CHATS_LOADER(state, payload) {
    state.chatsSkeletonLoader = payload;
  },
  UPDATE_COMMENT_PRIVATE_DISCUSSION: (state, comment)=>{
    state.privateTopicComments = __updateTopicDiscussionComments(state.privateTopicComments, comment)
  },
  UPDATE_COMMENT_PUBLIC_DISCUSSION: (state, comment)=>{
    state.publicTopicComments = __updateTopicDiscussionComments(state.publicTopicComments, comment)
  },
  SET_PUBLIC_DISUCSSION_USERS: (state, users) => state.publicDiscussionUsers = users,
  SET_PRIVATE_DISUCSSION_USERS: (state, users) => state.privateDiscussionUsers = users,
  TOGGLE_DICOVER_TOPIC_DISCUSSION_LOADER: (state, toggle) => {state.discoverTopicDiscussionLoader = toggle},

  DELETE_COMMENT_PRIVATE_DISCUSSION: (state,comment)=>{
    state.privateTopicComments = __deleteTopicComment(state.privateTopicComments, comment)
   },
  DELETE_COMMENT_PUBLIC_DISCUSSION: (state, comment)=>{
    state.publicTopicComments = __deleteTopicComment(state.publicTopicComments, comment)
   },
   SET_SELECTED_DISCUSSION_ARTICLE_ID: (state, articleId) =>{ state.selectedDiscussionArticleId = articleId },
   SET_DISCUSSION_INVITED_USERS(state, payload) {
    state.topicDiscussionInvitedUsers = payload;
  },
  SET_PRIVATE_DISCUSSION_CHAT_CURRENT_PAGE(state, payload){
    state.privateDiscussionChatCurrentPage = payload
  },
  SET_PUBLIC_DISCUSSION_CHAT_CURRENT_PAGE(state, payload){
    state.publicDiscussionChatCurrentPage = payload
  },
  RESET_DISCUSSION_CHAT_CURRENT_PAGE(state){
    state.privateDiscussionChatCurrentPage = 1
    state.publicDiscussionChatCurrentPage = 1
    state.isMoreDiscussionChatPage = false
  },
  SET_DISCUSSION_CHAT_LIMIT(state, payload){
    state.isMoreDiscussionChatPage = payload
  },
  EMPTY_TOPIC_PUBLIC_PRIVATE_DISCUSSIONS(state){
    state.publicTopicComments = []
    state.privateTopicComments = []
  },
  SET_TYPING_USERS(state, users){
    state.typingUsers = users
  }
}
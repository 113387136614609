<template>
  <MessageToaster :visible="true"/>
  <router-view></router-view>
  <!-- <bot-widget/> -->
</template>

<script>
import MessageToaster from './components/BaseComponents/MessageToaster.vue';
export default {
  name: "App",
  components: {
    MessageToaster
    // BotWidget
  },
  watch: {
      $route: {
          immediate: true,
      handler(to) {
        this.displayChatBotIcon()
              document.title = to.meta.title || 'Glocal';
          }
      },
  },
  methods: {
    /**
     * used to toggle the display of the chatbot icon 
     */
    displayChatBotIcon() {
      //check if chat bot has been initiaillized and the current route is '/'
      if (document.getElementById("bp-web-widget-container") && window.location.pathname === '/') {
        // get the attributes of the chatbot div 
        let chatBot = document.getElementById("bp-web-widget-container")
        // set style to none
        chatBot.style.display = 'none'
      }
      else if (document.getElementById("bp-web-widget-container") && window.location.pathname !== '/') {
        // get the attributes of the chatbot div
        let chatBot = document.getElementById("bp-web-widget-container")

        // if the current page is not the landing page and the style is not set to block
        if (chatBot.style.display === 'none')
          chatBot.style.display = 'block'
      }
      
    }
  }
};
</script>

<style lang='scss'>
@import "./assets/icons/icons.css";
</style>

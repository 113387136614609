<template>
  <div class="landing-page-container">
    <hero-section></hero-section>
    <news-environment-section></news-environment-section>
    <strategic-action-section></strategic-action-section>
    <about-us-section></about-us-section>
    <expert-professional-section></expert-professional-section>
    <landing-page-footer></landing-page-footer>
  </div>
</template>

<script>
import HeroSection from "../components/LandingPageComponents/HeroSection.vue";
// import StrategicActionSection from "../components/LandingPageComponents/StrategicActionSection.vue";
import NewsEnvironmentSection from "../components/LandingPageComponents/NewsEnvironmentSection.vue";
import { defineAsyncComponent } from 'vue'
// import ExpertProfessionalSection from "../components/LandingPageComponents/ExpertProfessionalSection.vue";
// import LandingPageFooter from "../components/layout/LandingPageFooter.vue";
// import AboutUsSection from "../components/LandingPageComponents/AboutUsSection.vue"

export default {
  components: {
    HeroSection,
    StrategicActionSection: defineAsyncComponent(() => import('@/components/LandingPageComponents/StrategicActionSection.vue')),
    NewsEnvironmentSection,
    ExpertProfessionalSection: defineAsyncComponent(() => import('@/components/LandingPageComponents/ExpertProfessionalSection.vue')),
    LandingPageFooter: defineAsyncComponent(() => import('@/components/layout/LandingPageFooter.vue')),
    AboutUsSection: defineAsyncComponent(() => import('@/components/LandingPageComponents/AboutUsSection.vue')),
  },
};
</script>

<style lang="scss">
.landing-page-container {
  background-color: $color-black;
  overflow: hidden;
}
</style>

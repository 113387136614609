
export const __updateTopicDiscussionComments = (map, comment) => {

    if (!comment?.parent){
        map.set(comment?.id, comment)
    }
    else{
        const parentComment = map.get(comment?.parent)
        const childIndex = parentComment?.child?.findIndex(childComment=> childComment.id === comment.id)
        if (parentComment && childIndex > -1 && parentComment.child.length > 0){
            parentComment.child[childIndex] = comment
        }
        else {
            parentComment.child.push(comment)
        }
        map.set(parentComment?.id, parentComment)
    }

    return map
  
}


export const __deleteTopicComment = (map, comment) => {

    if (!comment?.parent) {
        map.delete(comment.discussion_id)
    }else{
        const parentComment = map.get(comment?.parent)
        const childIndex = parentComment.child.findIndex(foundComment => foundComment.id === comment.discussion_id )
        parentComment.child.splice(childIndex,1)
        map.set(parentComment.id ,parentComment)
    }

    return map
}
export default {
  getSearchedArticles(state) {
    return state.articles;
  },
  getPDBSkeletonLoader(state) {
    return state.searchingPDBArticles;
  },
  getInsightsSkeletonLoader(state) {
    return state.searchingInsightsArticles;
  },
  getNextUrl(state) {
    return state.nextUrl;
  },
  getPreviousUrl(state) {
    return state.previousUrl;
  },
  getTotalArticles(state) {
    return state.totalArticles;
  },
  getSearchQuery(state) {
    return state.searchQuery;
  },
  getSearchedTopic(state) {
    return state.seachedTopic;
  },
  getStartDate(state) {
    return state.startDate;
  },
  getEndDate(state) {
    return state.endDate;
  },
  getIncludedSources(state) {
    return state.includedSources;
  },
  getTopicSource(state) {
    return state.topicSource;
  },
  getIncludedSelectedSources(state) {
    return state.selectedIncludeSources;
  },
  getExcludedSources(state) {
    return state.excludedSources;
  },
  getExcludedSelectedSources(state) {
    return state.selectedExcludeSources;
  },
  getInsightsStartDate(state) {
    return state.insightsStartDate;
  },
  getInsightsEndDate(state) {
    return state.insightsEndDate;
  },
  getInsightsIncludedSources(state) {
    return state.insightsIncludedSources;
  },
  getInsightsIncludedSelectedSources(state) {
    return state.selectedInsightsIncludeSources;
  },
  getInsightsExcludedSources(state) {
    return state.insightsExcludedSources;
  },
  getSelectedSpecificSources(state) {
    return state.selectedSpecificSources;
  },
  getInsightsExcludedSelectedSources(state) {
    return state.selectedInsightsExcludeSources;
  },
  getSources(state) {
    return state.sources;
  },
  getPDBSourcesLoader(state) {
    return state.searchingPdbSources;
  },
  getInsightsSources(state) {
    return state.insightsSources;
  },
  getPDBInsightsLoader(state) {
    return state.searchingInsightsSources;
  },
  getInsights(state) {
    return state.insights;
  },
  getSaveSearchFilters(state) {
    return state.savedSearchFilter;
  },
  getRecentSearches(state) {
    return state.articleSearchHistory;
  },
  getDiscoverArticleComments(state) {
    return state.discoverArticleComments;
  },
  getDiscussionComments(state) {
    return state.topicComments;
  },
  getLimitError(state) {
    return state.limitError;
  },
  getPrivateDiscussionComments(state) {
    return state.privateTopicComments;
  },
  getPublicDiscussionComments(state) {
    return state.publicTopicComments;
  },
  // getDiscussionSkeletonLoader(state) {
  //   return state.discussionSkeletonLoader;
  // },
  getTopic(state){
    return state.topic
  },
  getTopicURL(state) {
    return state.invitedTopicURL;
  },
  getTopicDetails(state) {
    return state.topicDetails;
  },
  invitedArticleId(state) {
    return state.invitedArticleId;
  },
  getArticleTotalResults(state) {
    return state.totalResults;
  },
  getCurrenPageNumber(state) {
    return state.currentPage;
  },
  getFetchedSources(state) {
    return state.fetchedSources;
  },
  getSortFilter(state) {
    return state.sortFilter;
  },
  getInsightsFetchedSources(state) {
    return state.fetchedInsightsSources;
  },
  getArticleReadView(state) {
    return state.articleReadView;
  },
  getFilterCounts(state) {
    return state.filterCounts;
  },
  getTopicRoute(state) {
    return state.topicRoute;
  },
  getOldSelectedTopics(state) {
    return state.oldTopics;
  },
  getDiscussionCollapseState(state) {
    return state.discussionCollapse;
  },
  getDiscoverModules(state) {
    return state.discoverModules;
  },
  getInsightsTotalResults(state) {
    return state.insightsTotalResults;
  },
  getInisghtsCurrenPageNumber(state) {
    return state.insightsCurrentPage;
  },
  getInsightsTotalArticles(state) {
    return state.insightsTotalArticles;
  },
  getInsightsPageOffset(state) {
    return state.insightsPageOffset;
  },
  getArticlesPageOffset(state) {
    return state.articlePageOffset;
  },
  getPDBIncludedKeywords(state) {
    return state.PDBIncludeKeywords;
  },
  getPDBExcludedKeywords(state) {
    return state.PDBExcludeKeywords;
  },
  getInsightsIncludedKeywords(state) {
    return state.insightsIncludeKeywords;
  },
  getInsightsExcludedKeywords(state) {
    return state.insightsExcludeKeywords;
  },
  getSuggestionInterests(state) {
    return state.suggestions;
  },
  glocalModuleLoader: (state) => state.glocalModuleLoader,
  selectedModule: (state) => state.selectedModule,
  trendingNewsFilters: (state) => state.trendingNewsFilter,
  trendingResearchFilter: (state) => state.trendingResearchFilter,
  activeTopicTab: (state) => state.activeTopicTab,
  pdbExactKeyword: (state) => state.pdbExactKeyword,
  insightExactKeyword: (state) => state.insightExactKeyword,
  openInitialTopicModal: state => state.openInitialTopicModal,
  sourcesByCountry: state => state.sourcesByCountry,
  insightSourcesByCountry: state => state.insightSourcesByCountry,
  initialJobId: state => state.initialJobId
  
};
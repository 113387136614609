import ApiService from "../../../services/api.service";
import Cookies from "js-cookie";

export default {
  connectDiscussionSocket(context, payload) {
    const url = `${process.env.VUE_APP_ROOT_WEBSOCKET}pdb-discussion/${payload.interest_group}/?token=${Cookies.get(
      "access_token"
    )}`

    let connection = context.state.discussionSocketConnection;

    if (connection) {
      connection.close();
      connection = null;
    }

    connection = new WebSocket(url);

    context.state.discussionSocketConnection = connection


    connection.onopen = (connected) => {
      if (connected){
        const userMap = new Map()
        connection.onmessage = (event) => {
          const discussions = JSON.parse(event.data);
          if (discussions.action === 'CREATED'){
            const group = payload.isPrivate ? 'PRIVATE' : 'PUBLIC'
            context.commit(`UPDATE_COMMENT_${group}_DISCUSSION`, JSON.parse(discussions.data));
          }else{
            userMap.set(discussions.data.user_id, discussions.data)
            context.commit('SET_TYPING_USERS', Array.from(userMap.values()).filter(user=>user?.is_typing === true))
          }
        };
      }
    };
    
  },

  // Comments Of Discussion Section

  createDiscussionComment(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/create/`,
        payload
      )
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchDiscussionComments(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_DISCUSSION_COMMENTS", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setDiscussionComments(context, payload) {
    return new Promise((resolve, reject) => {
      // if the page number is passed in the payload then use that specific page number else use the page number from state for respective groups
      let currentPage = 1
      if (!Object.hasOwn(payload, 'page'))
        currentPage = payload.group === 'PRIVATE' ? context.state.privateDiscussionChatCurrentPage : context.state.publicDiscussionChatCurrentPage 
      else
        currentPage = payload.page
      let requestUrl = `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/?ordering=-created_at&page=${currentPage}&page_size=${context.state.discussionChatsPageSize}`;
      if( payload.filterOptions && payload.filterOptions.user ){
        requestUrl = requestUrl + `&multiple_users=${payload.filterOptions.user}`
        if(payload.filterOptions.startDate){
          requestUrl =
            requestUrl +
            `&data_range_after=${payload.filterOptions.startDate}&data_range_before=${payload.filterOptions.endDate}`;
        }
      }else if(payload.filterOptions && payload.filterOptions.startDate){
        requestUrl =
          requestUrl +
          `&data_range_after=${payload.filterOptions.startDate}&data_range_before=${payload.filterOptions.endDate}`;
       }
      ApiService.get(requestUrl)
        .then((response) => {
          if (response.status === 200) {
            if (payload.filterOptions && (payload.filterOptions.user || payload.filterOptions.startDate )){
              context.commit('EMPTY_TOPIC_PUBLIC_PRIVATE_DISCUSSIONS')
            }
            context.commit(
              `SET_${payload.group}_DISCUSSION_COMMENTS`,
              response.data.results.reverse()
            );
            context.commit('SET_DISCUSSION_CHAT_LIMIT', response.data.next ? true : false)
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateDiscussionCommentst({commit}, payload) {
    const group = payload.group
    delete payload.group
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/${payload.discussion_id}/update/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit(`UPDATE_COMMENT_${group}_DISCUSSION`,response.data)
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteDiscussionComments({commit}, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/${payload.discussion_id}/destroy/`
      )
        .then((response) => {
          if (response.status === 204) {
            commit(`DELETE_COMMENT_${payload.group}_DISCUSSION`,payload)
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  reportaDiscussion(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`workspace/${payload.instance_id}/report/`, payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Message section Api's

  connectChattingSocket(context, payload) {
    const url = `${process.env.VUE_APP_ROOT_WEBSOCKET}pdb-chatting/${payload.interest_group}/?token=${Cookies.get(
      "access_token"
    )}`;
    
    let connection = context.state.ChattingSocketConnection;

    if (connection) {
      connection.close();
      connection = null;
    }

    connection = new WebSocket(url);

    connection.onopen = () => {
      console.log("Connected");
    };

    connection.onmessage = (event) => {
      const discussions = JSON.parse(event.data);
      let oldChats = context.getters.getTopicChats.reverse();
      oldChats.push(discussions.data);
      context.commit("SET_TOPICS_CHATS", oldChats.reverse());
    };

    connection.onclose = () => {
      console.log("Connection closed");
    };
  },

  createChattingMessage(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/pdb-chatting/interest/${payload.interest_id}/chat/create/`,
        payload
      )
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchChattingMesssage(context, payload) {
    if (context.getters.getNextUrl) {
      return new Promise((resolve, reject) => {
      let requestUrl =`discover/pdb-chatting/interest/${payload.interest_id}/chat/list/?ordering=-created_at&page=${context.getters.getNextUrl}&page_size=${payload.page_size}`;
      ApiService.get(requestUrl)
        .then((response) => {
          let oldChats = context.getters.getTopicChats.reverse();
          if (response.status === 200) {
            context.commit("SET_NEXT_URL", response.data.next);
            if (oldChats) {
              let newChats = oldChats.concat(
                response.data.results
              );
              context.commit(
                "SET_TOPICS_CHATS",
                newChats.reverse()
              );
            } else {
              context.commit(
                "SET_TOPICS_CHATS",
                response.data.results.reverse()
              );
            }
            context.commit("SET_CHATS_LOADER", false);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    }
  },

  updateChattingMessage(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `discover/pdb-chatting/interest/${payload.interest_id}/chat/${payload.chat_id}/update/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteChattingMessage(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/pdb-chatting/interest/${payload.interest_id}/chat/${payload.chat_id}/delete/`
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  reportChattingMessage(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`workspace/${payload.instance_id}/report/`, payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateCommentList({commit},commentDetails){
    const {group, comment} = commentDetails
    commit(`UPDATE_${group}`,comment)
  },
  // fetchDiscussionUsers({commit},{interest_group_id, interest_id, group }){
  //   return new Promise((resolve, reject) => {   
  //   ApiService.get(
  //         `discover/group/${interest_group_id}/discussion/${interest_id}/comment/users/`
  //     )
  //       .then((response) => {
  //         if (response.status === 200) {
  //           commit(`SET_${group}_DISUCSSION_USERS`, response.data)
  //           resolve(response);
  //         }
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },
  // fetchPublicDiscussionUsers(_,{interest_group_id, interest_id}){
  //   return new Promise((resolve, reject) => {   
  //   ApiService.get(
  //       `discover/group/${interest_group_id}/discussion/${interest_id}/comment/users`
  //     )
  //       .then((response) => {
  //         if (response.status === 200) {
  //           console.log("RESPONSE", response)
  //           resolve(response);
  //         }
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },
  filterComments({commit}, filterOption){
    let apiUrl = `discover/group/${filterOption.interestGroupId}/?data_range_after=${filterOption.endDate}&data_range_before=${filterOption.startDate}`
    if(filterOption.user)
        apiUrl = apiUrl + `&multiple_users=${filterOption.user}`
      return new Promise((resolve, reject) => {   
    ApiService.get(
      apiUrl
      )
        .then((response) => {
          if (response.status === 200) {
            commit(`SET_${filterOption.group}_DISCUSSION_COMMENTS`,response.data)
            console.log("RESPONSE", response.data)
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    
},

likeComment(_,commentDetails){
  const apiUrl = `discover/group/${commentDetails.interestGroupId}/discussion/${commentDetails.interestId}/comment/${commentDetails.commentId}/action/create/`
  return new Promise((resolve, reject) => {   
  ApiService.post(
    apiUrl,
    commentDetails.action
    )
      .then((response) => {
        if (response.status === 201) {
          // commit(`SET_${filterOption.group}_DISCUSSION_COMMENTS`,response.data)
          // console.log("RESPONSE", response.data)
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  })
},

deleteLikeDislike(_,commentDetails){
  const apiUrl = `discover/group/${commentDetails.interestGroupId}/discussion/${commentDetails.interestId}/comment/${commentDetails.commentId}/action/delete/`
  return new Promise((resolve, reject) => {   
  ApiService.delete(
    apiUrl
    )
      .then((response) => {
        if (response.status === 204) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  })
},

toggleDiscoverTopicCommentLoader({commit,state}){
  commit('TOGGLE_DICOVER_TOPIC_DISCUSSION_LOADER',!state.discoverTopicDiscussionLoader)
},
setSelectedDiscussionArticleId: ({commit}, articleId)=>{ commit('SET_SELECTED_DISCUSSION_ARTICLE_ID', articleId) },


// Comments Of Discussion Section Invited Users

fetchDiscussionInvitedUsers({commit}, payload) {
  return new Promise((resolve, reject) => {
    ApiService.get(
     `interest-group/${payload.interest_group_id}/users/?permission=admin&permission=editor`
    )
      .then((response) => {
          commit("SET_DISCUSSION_INVITED_USERS", response.data.results);
          resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
},

incrementDiscussionChatCurrentPage({commit, state}, group){
  const page = group === 'PRIVATE' ? state.privateDiscussionChatCurrentPage : state.publicDiscussionChatCurrentPage
  commit(`SET_${group}_DISCUSSION_CHAT_CURRENT_PAGE`, page + 1)
},

resetDiscussionChatCurrentPage({commit}){
  commit('RESET_DISCUSSION_CHAT_CURRENT_PAGE')
},
emptyTopicPublicAndPrivateDiscussion({commit}){
  commit('EMPTY_TOPIC_PUBLIC_PRIVATE_DISCUSSIONS')
},

sendTypingStatusOfUsers({state}, typingStatus){
  if (state.discussionSocketConnection){
    state.discussionSocketConnection.send(JSON.stringify({is_typing:typingStatus}))
  }
}
};




import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      discussionSocketConnection: null,
      ChattingSocketConnection: null,
      topicDiscussions: null,
      topicComments: [],
      privateTopicComments: new Map(),
      publicTopicComments: new Map(),
      discussionSkeletonLoader: true,
      topicChats: [],
      nextUrl: 1,
      privateDiscussionUsers:[],
      publicDiscussionUsers:[],
      discoverTopicDiscussionLoader:false,
      selectedDiscussionArticleId: null,
      topicDiscussionInvitedUsers: [],
      privateDiscussionChatCurrentPage:1,
      publicDiscussionChatCurrentPage:1,
      discussionChatsPageSize:10,
      isMoreDiscussionChatPage: false,
      typingUsers: []
    };
  },
  actions,
  mutations,
  getters,
};
